/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  extendTheme,
  ThemeConfig,
  theme as chakraTheme,
} from '@chakra-ui/react';

import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindconf from './tailwind.config';
import { Config } from 'tailwindcss';
import colors from './colors';


const tailwind = resolveConfig(tailwindconf as Config);

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
};

// @ts-ignore
Object.keys(chakraTheme.colors).forEach((color) => {
  // @ts-ignore
  if (tailwind.theme.colors[color]) {
    // @ts-ignore
    chakraTheme.colors[color] = tailwind.theme.colors[color];
  }
});

const theme = extendTheme({
  ...chakraTheme,
  config,
  colors: {
    // @ts-ignore
    ...tailwind.theme!.colors!,
    default: colors.fijord
  },
});

export default theme;
