import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

import {
  Accordion,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Grid,
  GridItem,
  IconButton,
  Switch,
  color,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react';
import Link from 'next/link';
import { BsFacebook, BsInstagram, BsList } from 'react-icons/bs';
import { ArticleCategory } from '../../types';
import { Gallery } from './Gallery';
import { InfosFuerAnfaenger } from './InfosFuerAnfaenger';
import { News } from './News';
import { Verein } from './Verein';
import { ChangeEvent } from 'react';

interface MobileNavProps {
  categories: ArticleCategory[];
  onColorModeChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export function MobileNav({ categories, onColorModeChange }: MobileNavProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {colorMode } = useColorMode()

  return (
    <>
      <Center className="dark:top-shadow fixed bottom-0 w-full bg-gray-700">
        <IconButton
          className="-translate-y-4"
          isRound
          colorScheme="default"
          size="lg"
          aria-label="Menü öffnen"
          onClick={onOpen}
          icon={<BsList />}
        />
      </Center>
      <Drawer
        closeOnEsc
        isOpen={isOpen}
        placement="bottom"
        onClose={onClose}
        size="xl"
      >
        <DrawerContent className="shadow-default" backgroundColor="gray.700" color="white">
          <DrawerCloseButton />

          <DrawerHeader>Menü</DrawerHeader>
          <DrawerBody>
            <nav>
              <Accordion allowToggle>
                <News categories={categories} onClick={onClose} />
                <Verein onClick={onClose} />
                <Gallery onClick={onClose} />
                <InfosFuerAnfaenger onClick={onClose} />
              </Accordion>
            </nav>
          </DrawerBody>
          <DrawerFooter justifyContent="start">
            <Grid gap={6} templateColumns={{ sm: '2fr', md: '3fr' }}>
              <GridItem>
                <ul className="flex list-none flex-row items-center">
                  <li className="pr-4">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/svlitzelstettenbogensport/"
                    >
                      <BsInstagram size="2rem"></BsInstagram>
                    </a>
                  </li>
                  <li className="pr-4">
                    <a
                      target="_blank"
                      href="https://m.facebook.com/people/SV-Litzelstetten-Bogensport/100063784800865/"
                    >
                      <BsFacebook size="2rem"></BsFacebook>
                    </a>
                  </li>
                  <li className="float-right pr-4">
                    <Link href="/impressum">
                      Impressum
                    </Link>
                  </li>
                  <li className="float-right pr-4">
                    <Link href="/datenschutz">
                      Datenschutzerklärung
                    </Link>
                  </li>
                </ul>
              </GridItem>
              <GridItem>
                <Switch isChecked={colorMode === 'dark'} onChange={onColorModeChange} />
              </GridItem>
            </Grid>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
