import { Option, Some } from '@sniptt/monads';
import { StrapiImage } from '../types';
import { getStrapiURL } from './api';

export function getStrapiMedia(media?: StrapiImage): Option<string> {

  return Some(media === null ? undefined : media)
    .map(m => m.attributes.url)
    .map(url => url.startsWith('/') ? getStrapiURL(url): url)
}
