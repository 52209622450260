import {
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import { NavItem } from '../../components/NavItem/NavItem';
import { ArticleCategory } from '../../types';

interface NewsProps {
  categories: ArticleCategory[];
  onClick: () => void;
}

export const News = ({ categories, onClick }: NewsProps): JSX.Element => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          Berichte & Nachrichten
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <ul>
          {categories.map((category) => (
            <NavItem

              key={category.id}
              title={category.attributes.name}
              href={'/article/category/' + category.attributes.slug}
              onClick={onClick}
            />
          ))}
          <NavItem
            title='Wettkampfergebnisse'
            href="/wettkapmfergebnisse"
            onClick={onClick}
          />
        </ul>
      </AccordionPanel>
    </AccordionItem>
  );
};
