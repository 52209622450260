import { useEffect, useState } from 'react';

function useIsScrolled() {
  const [isScrolled, setScrolled] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const updateScroll = () => {
        setScrolled(window.pageYOffset > 50);
      };

      window.addEventListener('scroll', updateScroll);
      return () => window.removeEventListener('scroll', updateScroll);
    }
  }, []);

  return isScrolled;
}

export { useIsScrolled };
