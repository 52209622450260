const plugin = require('tailwindcss/plugin');

const colors = require('./colors')

module.exports = {
  mode: 'jit',
  darkMode: 'class',
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './container/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    extend: {
      borderRadius: {
        default: '0.125rem'
      },
      boxShadow: {
        default: 'box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05)'
      },
      textShadow: {
        default: '2px -1px 2px theme(colors.default.100)',
      },

      colors: {
        ...colors,
        default: colors['fijord'],
      },
    },
    screens: {
      sm: '30em',
      md: '48em',
      lg: '62em',
      xl: '80em',
      '2xl': '96em',
    },
  },
  plugins: [
    plugin(({ matchUtilities, theme }) => {
      matchUtilities(
        {
          'text-shadow': (value) => ({
            textShadow: value,
          }),
        },
        { values: theme('textShadow') }
      );
    }),
  ],
};
