import { Box } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';

type Orientation = 'right' | 'left' | 'center';

interface NavItemProps {
  title: string;
  href: string;
  icon?: JSX.Element;
  orientation?: Orientation;
  onClick: () => void;
}

const orentationAlignment = (
  orientation: Orientation = 'center'
): 'justify-center' | 'justify-start' | 'justify-end' => {
  const map: Record<
    Orientation,
    'justify-center' | 'justify-start' | 'justify-end'
  > = {
    center: 'justify-center',
    left: 'justify-start',
    right: 'justify-end',
  };
  return map[orientation];
};

function NavItem({ title, href, icon, orientation, onClick }: NavItemProps) {
  return (
    <Box as={Link}
      href={href}
      onClick={onClick}
      className={`flex w-full ${orentationAlignment(
        orientation
      )} py-2 pl-2 align-middle hover:bg-default-400 hover:text-white`}
    >
      {icon && React.cloneElement(icon, { h: 6, className: 'mr-3', 'aria-hidden': true })}
      {title}
    </Box>
  );
}

export { NavItem };
