import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Collapse,
  Container,
  Heading,
  Icon,
  IconButton,
  List,
  ListIcon,
  ListItem,
  SlideFade,
  useCheckbox,
  useColorMode,
  useTheme,
} from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { fetchAPI, RequestMetada } from '../lib/api';
import { StrapiEvent } from '../types';
import { formatDate } from '../utils/dateUtils';
import { isAfter, parseISO } from 'date-fns';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config';
import { Config } from 'tailwindcss';
import { None, Some } from '@sniptt/monads';

export interface EventsProps {
  eventsEntry: StrapiEvent[];
  meta: RequestMetada;
  path: string;
  label: string;
  icon: React.ElementType;
}

const fullConfig = resolveConfig(tailwindConfig as Config);

const defaultColors = Some(fullConfig.theme?.colors || undefined).andThen((c) =>
  c.default ? Some(c.default) : None
);

function Events({ eventsEntry, meta: m, path, label, icon }: EventsProps) {
  const [events, setEvents] = useState<StrapiEvent[]>(eventsEntry);
  const [meta, setMeta] = useState<RequestMetada>(m);

  const { colorMode } = useColorMode();

  const fetchEvents = useCallback(function (page: number) {
    fetchAPI<StrapiEvent[]>(path, {
      populate: '*',
      'pagination[page]': page,
      'pagination[pageSize]': 5,
      sort: 'datum',
    }).then((response) => {
      setEvents(response.data);
      setMeta(response.meta);
    });
  }, []);

  const pagination = meta.pagination;

  const fetchNextPage = () => fetchEvents(pagination.page + 1);
  const fetchPrevPage = () => fetchEvents(pagination.page - 1);

  const [show, setShow] = useState<boolean>(false);

  const toggle = () => setShow((old) => !old);

  return (
    <Container as="aside" maxW={'container.xl'} className="p-0" padding={0}>
      <Box className="rounded-default shadow-default my-0 w-full overflow-hidden pb-2 xl:mr-8">
        <button
          onClick={toggle}
          type="button"
          className="bg-default-500 dark:bg-default-950 flex w-full items-center justify-between px-4 py-2 align-middle"
        >
          <Heading as="h2" size="lg">
            {label}
          </Heading>
          <Icon as={show ? ChevronUpIcon : ChevronDownIcon} boxSize="2em" />
        </button>
        <Collapse
          animateOpacity
          in={show}
          className="dark:bg-default-500 bg-white pt-2"
        >
          <List>
            {events.map((event) => (
              <ListItem key={event.attributes.slug} className="px-4 pb-4">
                <ListIcon as={icon} />
                <p className="inline font-bold">
                  {event.attributes.label}{' '}
                  {event.attributes.datum && formatDate(event.attributes.datum)}
                </p>
                {event.attributes.beschreibung !== null && (
                  <small className="block">
                    {event.attributes.beschreibung}
                  </small>
                )}
                {event.attributes.detailansicht && (
                  <Link
                    href={`/calendar/${event.attributes.slug}`}
                    className="text-xs text-blue-600"
                  >
                    Weitere Infos
                  </Link>
                )}
              </ListItem>
            ))}
            {events.length === 0 && (
              <ListItem className="p-4">
                <p className="inline pl-2 font-bold italic text-gray-400">
                  Keine Anstehenden Termine
                </p>
              </ListItem>
            )}
          </List>
          {pagination.pageCount > 1 && (
            <ButtonGroup
              variant="ghost"
              className="bg-default-800 dark:bg-default-200 flex w-full justify-between px-6 "
            >
              <IconButton
                isDisabled={pagination.page < 2}
                aria-label="Vorherige Seite"
                onClick={fetchPrevPage}
              >
                <ChevronLeftIcon
                  color={
                    colorMode == 'dark'
                      ? defaultColors
                          .map((color) => color[800])
                          .unwrapOr('#000')
                      : defaultColors
                          .map((color) => color[800])
                          .unwrapOr('#FFF')
                  }
                  boxSize="2em"
                />
              </IconButton>
              <IconButton
                isDisabled={pagination.page >= pagination.pageCount}
                aria-label="Nächste Seite"
                onClick={fetchNextPage}
              >
                <ChevronRightIcon
                  boxSize="2em"
                  color={
                    colorMode == 'dark'
                      ? defaultColors
                          .map((color) => color[800])
                          .unwrapOr('#000')
                      : defaultColors
                          .map((color) => color[800])
                          .unwrapOr('#FFF')
                  }
                />
              </IconButton>
            </ButtonGroup>
          )}
        </Collapse>
      </Box>
    </Container>
  );
}

export { Events };
