import {
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import { NavItem } from '../../components/NavItem/NavItem';

interface VereinProps {
  onClick: () => void;
}

export const Verein = ({ onClick }: VereinProps): JSX.Element => {
  return (
    <AccordionItem>
      <span className='flex flex-row align-middle'>
        <Link href="/verein" className='w-full pl-4 py-2'>
          <Box flex="1" textAlign="left" onClick={onClick}>
            Verein
          </Box>
        </Link>
        <AccordionButton className='pr-4' flex={0}>
          <AccordionIcon />
        </AccordionButton>
      </span>


      <AccordionPanel>
        <ul>
          <NavItem title="Trainer" href="/verein/trainer" onClick={onClick} />
          <NavItem title="Der Vorstand" href="/verein/vorstand" onClick={onClick} />
        </ul>
      </AccordionPanel>
    </AccordionItem>
  );
};
