import qs from 'qs';

export function getStrapiURL(path: string): string {
  const url = process.env.NEXT_PUBLIC_STRAPI_API_URL || 'http://127.0.0.1:1337';
  return `${url}${path}`;
}

export interface RequestMetada {
  pagination: {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
  };
}

export async function fetchAPI<R>(
  path: string,
  urlParams: object = {},
  options: object = {}
): Promise<{ data: R; meta: RequestMetada }> {
  const mergedOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };

  const queryString = qs.stringify({ ...urlParams, locale: 'de' });
  const requestUrl = `${getStrapiURL(
    `/api${path}${queryString ? `?${queryString}` : ''}`
  )}`;

  try {
    const response = await fetch(requestUrl, mergedOptions);

    if (!response.ok) {
      const errorMsg = `[${requestUrl}] ${response.statusText} ${response.status}`;
      throw new Error('An error occured.' + errorMsg);
    }
    return await response.json();
  } catch (err) {
    console.group(path);
    console.log('path', path);
    console.error(err);
    console.groupEnd();

    return Promise.reject();
  }
}

type UpdateBody<R> = {
  [K in keyof R]:
    | {
        connect?: Array<{ id: string | number }>;
        disconnect?: Array<{ id: string | number }>;
        set: Array<{ id: string | number }>;
      }
    | R[K];
};

function updateAPI<R>(path: string, body: UpdateBody<R>) {}
