import {
  Box,
  Container,
  Heading,
  Icon,
  List,
  ListIcon,
  ListItem,
} from '@chakra-ui/react';
import React from 'react';
import { PageContentEntry } from '../types';
import { StarIcon, ChevronRightIcon } from '@chakra-ui/icons';
import Link from 'next/link';

export function PageContent({ content }: { content: PageContentEntry[] }) {
  return (
    <Container
      as="aside"
      maxW={'container.xl'}
      className="container sticky top-28"
    >
      <Box className="my-0 w-full overflow-hidden rounded-default shadow-default xl:mr-8 bg-white dark:bg-default-500">
        <div className="bg-default-500 dark:bg-default-950 mb-2 flex w-full items-center justify-between py-2 px-4 align-middle">
          <Heading as="h2" size="lg">
            Inhalte
          </Heading>
          <Icon as={StarIcon} boxSize="2em" />
        </div>
        <List>
          {content.map((content) => (
            <ListItem
              key={content.id}
              className="hover:bg-default-600  p-4 text-default-600 dark:text-gray-200 hover:text-white"
            >
              <ListIcon as={ChevronRightIcon} />
              <Link href={content.path} className="text-current">
                {content.name}
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
}
