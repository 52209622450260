import { AccordionItem, AccordionButton, Box } from '@chakra-ui/react';

import Link from 'next/link';
import React from 'react';

interface GalleryProps {
  onClick: () => void;
}

export const Gallery = ({ onClick }: GalleryProps): JSX.Element => {
  return (
    <AccordionItem>
      <AccordionButton as={Link} href="/gallery">
        <Box onClick={onClick} flex="1" textAlign="left">
          Galerie
        </Box>
      </AccordionButton>
    </AccordionItem>
  );
};
