import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

export function useIsLandigPage() {
  const router = useRouter();

  const [isLandingPage, setIsLandingPage] = useState(false);
  useEffect(() => {
    setIsLandingPage(router.pathname === '/');
  }, [router]);

  return isLandingPage;
}
