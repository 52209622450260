import {
  Alert,
  AlertIcon,
  AlertStatus,
  AlertTitle,
  Box,
  Container,
  Fade,
  Grid,
  GridItem,
  Heading,
  Icon,
  List,
  ListItem,
  SkipNavContent,
  Stack,
  Switch,
  Text,
  ThemeTypings,
  useBreakpointValue,
  useColorMode,
} from '@chakra-ui/react';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import clsx from 'clsx';
import {
  format,
  formatISO,
  isAfter,
  isBefore,
  parse,
  parseISO,
} from 'date-fns';
import Link from 'next/link';
import React, { Fragment, useEffect } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { BsFacebook, BsInstagram } from '../components/Icon';
import { DynamicImage, StaticImage } from '../components/Image/Image';
import { useIsLandigPage } from '../hooks/UseIsLandingPage';
import { useIsScrolled } from '../hooks/useIsScrolled';
import { RequestMetada } from '../lib/api';
import {
  HallendienstEntry,
  HallendienstPlan,
  LayoutConfig,
  PageContentEntry,
  StrapiAlert,
  StrapiEvent,
  StrapiImage,
  TrainingsZeiten,
} from '../types';
import { Events } from './Events';
import { MobileNav } from './nav/MobileNav';
import { Nav, NavProps } from './nav/Nav';
import { PageContent } from './PageContent';
import { useRouter } from 'next/router';
import { CalendarIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
  formatDate,
  formatToUTC,
  nextWeekday,
  normalice,
  Patterns,
  timeToDate,
  toISO,
} from '../utils/dateUtils';
import { BsLink45Deg } from 'react-icons/bs';

import uiTheme from '../theme';
import { Some } from '@sniptt/monads';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

type LayoutProps = React.PropsWithChildren<
  NavProps & {
    background: {
      attributes: {
        image: { data: StrapiImage };
      };
    };
    events: {
      data: StrapiEvent[];
      meta: RequestMetada;
    };
    trainings: {
      data: HallendienstPlan;
      meta: RequestMetada;
    };
    normalTrainings: {
      data: TrainingsZeiten;
      meta: RequestMetada;
    };
    alerts: StrapiAlert;
    pageContent?: PageContentEntry[];
  } & LayoutConfig
>;

const BackgroundComponent = ({
  image,
  className,
  isScrolled,
}: {
  image: StrapiImage;
  className?: string;
  isScrolled: boolean;
}) => {
  return (
    <Box
      className={clsx(
        'background-image relative w-screen overflow-hidden lg:h-screen',
        className
      )}
    >
      <Box
        className={clsx(
          'z-10 mt-12 w-full scale-150 transition-transform duration-150 ease-out',
          {
            'translate-y-[150px] scale-100': isScrolled,
          }
        )}
      >
        <DynamicImage
          image={image}
          className="max-h-[500px] object-cover xl:max-h-full"
          alt={image?.attributes?.alternativeText || ''}
        />
        <Fade in={!isScrolled}>
          <div className="text-default-900 absolute left-1/2 top-1/2 inline-block -translate-x-1/2 -translate-y-1/2 align-middle">
            <Heading as="p" size="md" className="font-bold text-white">
              Wilkommen beim
            </Heading>
            <Heading
              as="h1"
              size="4xl"
              className="text-shadow-default mix-blend-multiply"
            >
              SVL - Bogensport
            </Heading>
          </div>
        </Fade>
      </Box>
    </Box>
  );
};

const trainingToEvent = (
  training: HallendienstEntry,
  i: number
): StrapiEvent => {
  const datum = new Date(training.attributes.date.date);
  const [hour, minute] = training.attributes.date.from.split(':');
  datum.setHours(Number.parseInt(hour));
  datum.setMinutes(Number.parseInt(minute));

  return {
    id: training.id,
    attributes: {
      beschreibung: training.attributes.verantwortlicher
        ? `Schlüsseldienst: ${training.attributes.verantwortlicher}`
        : 'Noch kein Schlüsseldienst',
      content: [],
      slug:
        'training-' +
        training.id +
        '-' +
        formatDate(training.attributes.date.date, Patterns.TIMELESS),
      updatedBy: training.attributes.updatedBy,
      createdBy: training.attributes.createdBy,
      seo: undefined,
      createdAt: training.attributes.createdAt,
      updatedAt: training.attributes.updatedAt,
      publishedAt: training.attributes.publishedAt,
      label:
        training.attributes.kommentar &&
        training.attributes.kommentar.length > 0
          ? training.attributes.kommentar
          : 'Training',
      datum: datum.toISOString(),
      detailansicht: false,
    },
  };
};

function Layout({
  children,
  categories,
  events,
  trainings,
  normalTrainings,
  background,
  alerts,
  pageContent,
  layout,
}: LayoutProps) {
  const isLandingPage = useIsLandigPage();

  const normalTrainingsToHallenplan = normalTrainings.data.attributes.zeiten
    .filter((training) => training.anzeigen)
    .map((z, i) => {
      const date = nextWeekday(z.date.day, new Date());

      const e: HallendienstEntry = {
        id: i,
        attributes: {
          createdAt: normalTrainings.data.attributes.createdAt,
          createdBy: normalTrainings.data.attributes.createdBy,
          updatedAt: normalTrainings.data.attributes.createdAt,
          updatedBy: normalTrainings.data.attributes.updatedBy,
          publishedAt: normalTrainings.data.attributes.publishedAt,
          date: {
            date: toISO(date),
            from: z.date.from,
            to: z.date.to,
          },
          kommentar: z.title,
          verantwortlicher: z.verantwortlicher
            ? z.verantwortlicher
            : 'Trainer|Vorstand',
          seo: undefined,
        },
      };

      return e;
    });

  const training = [...trainings.data, ...normalTrainingsToHallenplan].sort(
    (a, b) =>
      normalice(a.attributes.date.date).valueOf() -
      normalice(b.attributes.date.date).valueOf()
  );

  const newsOrder = useBreakpointValue({ base: 0, lg: 2 });

  const showMobileNav = useBreakpointValue({ base: true, sm: true, lg: false });

  const schemeMap: Record<AlertStatus, ThemeTypings['colorSchemes']> = {
    error: 'red',
    info: 'tower-gray',
    loading: 'tower-gray',
    success: 'tower-gray',
    warning: 'fire-bush',
  };
  const isScrolled = useIsScrolled();

  const router = useRouter();

  const { colorMode, setColorMode } = useColorMode();

  useEffect(() => {
    const theme =
      localStorage.getItem('theme') ||
      uiTheme.config.initialColorMode ||
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
    document.documentElement.classList.add(theme);
    setColorMode(theme);
  }, []);

  const changeTheme = (e: React.ChangeEvent<HTMLInputElement>): void => {
    document.documentElement.classList.remove('dark');
    document.documentElement.classList.remove('light');

    const theme = e.target.checked ? 'dark' : 'light';
    setColorMode(theme);
    document.documentElement.classList.add(theme);
    localStorage.setItem('theme', theme);
  };

  const alertsElement = (position: 'relative' | 'absolute') => (
    <div
      className={clsx(position, 'w-full px-8', {
        'bottom-8': position === 'absolute',
        'pt-8': position === 'relative',
      })}
    >
      {isLandingPage && alerts.data.attributes.messages.length > 0 && (
        <Stack spacing={3}>
          {alerts.data.attributes.messages
            .filter((m) => m.anzeigen) // More priority then other
            .filter(
              (m) =>
                !m.ablaufdatum || isBefore(new Date(), parseISO(m.ablaufdatum))
            )
            .map((m) => {
              return (
                <div>
                  <Alert
                    key={m.id}
                    variant="solid"
                    colorScheme={schemeMap[m.type]}
                    status={m.type}
                    className="rounded-default flex items-center"
                  >
                    <AlertIcon />
                    <AlertTitle>{m.label}</AlertTitle>
                    <ReactMarkdown
                      components={ChakraUIRenderer({
                        p: (props) => <Text mb={0}>{props.children}</Text>,
                      })}
                    >
                      {m.content}
                    </ReactMarkdown>
                  </Alert>
                </div>
              );
            })}
        </Stack>
      )}
    </div>
  );

  return (
    <>
      {isLandingPage && !showMobileNav && (
        <div className="relative">
          <BackgroundComponent
            image={background.attributes.image.data}
            isScrolled={isScrolled}
          />
          {alertsElement('absolute')}
        </div>
      )}
      {showMobileNav && (
        <div className="relative mb-8 flex w-full flex-col items-center justify-center">
          <Link href="/">
            <StaticImage
              src="/LogoSVLB_Transparent.png"
              layout="fixed"
              height={100}
              width={100}
              alt="Das Vereinslogo"
            />
          </Link>
          <Heading
            onClick={() => router.push('/')}
            as="h1"
            size="4xl"
            className="text-default-900 text-shadow-default py-2 text-center mix-blend-multiply"
          >
            SVL - Bogensport
          </Heading>
          {alertsElement('relative')}
        </div>
      )}

      <div className="flex h-full w-full flex-col">
        <Box
          as={'header'}
          zIndex={'banner'}
          className="header transition-height fixed left-0 top-0 z-10 w-full align-middle text-gray-800 duration-150 ease-linear "
        >
          {showMobileNav ? (
            <MobileNav
              categories={categories}
              onColorModeChange={changeTheme}
            />
          ) : (
            <Nav categories={categories} onColorModeChange={changeTheme} />
          )}
        </Box>
        <div className="flex-auto flex-shrink-0 flex-grow">
          <Box
            id="mainContent"
            className={clsx(
              'relativ anchor-offset-top min-h-screen scroll-mt-24',
              {
                'pb-14': showMobileNav,
              }
            )}
          >
            <SkipNavContent />
            <Grid
              templateColumns="repeat(12, minmax(0, 1fr))"
              gap={6}
              className={clsx(
                'mx-8 pt-2',
                isLandingPage ? 'lg:pt-8' : 'lg:pt-20'
              )}
            >
              {layout.showEvents && (
                <GridItem
                  order={newsOrder}
                  className={clsx('col-span-full my-4 w-full lg:col-span-4', {
                    sticky: !showMobileNav,
                  })}
                >
                  <Grid gap={6}>
                    {Some(training.slice(0, 2)).match({
                      some(entries) {
                        return (
                          <GridItem>
                            <Container
                              as="aside"
                              maxW={'container.xl'}
                              padding={0}
                            >
                              <Box className="bg-default-500 dark:bg-default-200 dark:text-default-800 mb-1 rounded-sm px-3 py-2 text-white">
                                <Heading as="h2" size="lg">
                                  Nächstes Training
                                </Heading>
                                <List className="pb-2 pt-2">
                                  {entries.map((entry) => (
                                    <ListItem className="mb-4">
                                      <Text fontWeight={600}>
                                        {entry.attributes.kommentar ||
                                          'Schlüsseldienst'}{' '}
                                        {formatDate(
                                          entry.attributes.date.date,
                                          Patterns.WEEKDAY
                                        )}{' '}
                                        {formatDate(
                                          timeToDate(
                                            entry.attributes.date.from
                                          ),
                                          Patterns.TIME_ONLY_NO_UNIT
                                        )}{' '}
                                        bis{' '}
                                        {formatDate(
                                          timeToDate(entry.attributes.date.to),
                                          Patterns.TIME_ONLY
                                        )}
                                      </Text>
                                      <Text>
                                        {entry.attributes.verantwortlicher ||
                                          '--'}
                                      </Text>
                                    </ListItem>
                                  ))}
                                  <Link
                                    href="/verein/training"
                                    className="bg-default-700 dark:bg-default-700 dark:text-default-200 cursor-pointer rounded-full px-4 py-2 text-white"
                                  >
                                    Alle Trainingseinheiten{' '}
                                    <ExternalLinkIcon as={BsLink45Deg} />
                                  </Link>
                                </List>
                              </Box>
                            </Container>
                          </GridItem>
                        );
                      },
                      none: <Fragment />,
                    })}
                    {events.data.length > 0 && (
                      <GridItem>
                        <Events
                          icon={CalendarIcon}
                          path="/events"
                          eventsEntry={events.data.map((e) => ({
                            ...e,
                            icon: CalendarIcon,
                          }))}
                          meta={events.meta}
                          label="Anstehende Termine"
                        />
                      </GridItem>
                    )}
                    {pageContent && pageContent.length > 0 && (
                      <GridItem>
                        <PageContent content={pageContent} />
                      </GridItem>
                    )}
                  </Grid>
                </GridItem>
              )}

              <GridItem
                className={clsx('my-4 w-full', {
                  'col-span-full': !layout.showEvents,
                  'col-span-full md:row-start-2 lg:col-span-8 lg:row-start-1':
                    layout.showEvents,
                })}
              >
                <Container maxW={layout.containerWidth} as="main" padding={0}>
                  {children}
                </Container>
              </GridItem>
            </Grid>
          </Box>
        </div>

        {!showMobileNav && (
          <Box
            as="footer"
            className="h-20 w-full flex-shrink-0 bg-gray-600 px-5 py-4 text-white"
          >
            <Grid gap={6} templateColumns={{ base: '2fr', md: '3fr' }}>
              <GridItem>
                <ul className="flex list-none flex-row items-center">
                  <li className="pr-4">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/svlitzelstettenbogensport/"
                    >
                      <BsInstagram size="2rem"></BsInstagram>
                    </a>
                  </li>
                  <li className="pr-4">
                    <a
                      target="_blank"
                      href="https://m.facebook.com/people/SV-Litzelstetten-Bogensport/100063784800865/"
                    >
                      <BsFacebook size="2rem"></BsFacebook>
                    </a>
                  </li>
                  <li className="float-right pr-4">
                    <Link href="/impressum">Impressum</Link>
                  </li>
                  <li className="float-right pr-4">
                    <Link href="/datenschutz">Datenschutzerklärung</Link>
                  </li>
                  <li className="float-right">
                    <Switch
                      colorScheme="default"
                      isChecked={colorMode === 'dark'}
                      onChange={changeTheme}
                    />
                  </li>
                </ul>
              </GridItem>
            </Grid>
          </Box>
        )}
      </div>
    </>
  );
}

export { Layout };
