import { AccordionItem, AccordionButton, Box } from '@chakra-ui/react';

import Link from 'next/link';
import React from 'react';

interface InfosFuerAnfaengerProps {
  onClick: () => void;
}

export const InfosFuerAnfaenger = ({
  onClick,
}: InfosFuerAnfaengerProps): JSX.Element => {
  return (
    <AccordionItem>
      <AccordionButton as={Link} href="/anfaengerInfos">
        <Box onClick={onClick} flex="1" textAlign="left">
          Infos für Anfänger
        </Box>
      </AccordionButton>
    </AccordionItem>
  );
};
