import {
  Box,
  Button,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Show,
  SkipNavLink,
} from '@chakra-ui/react';
import clsx from 'clsx';
import Link from 'next/link';
import { BsChevronDown } from 'react-icons/bs';
import { StaticImage } from '../../components/Image/Image';
import { useIsLandigPage } from '../../hooks/UseIsLandingPage';
import { useIsScrolled } from '../../hooks/useIsScrolled';
import { ArticleCategory } from '../../types';
import { MobileNav } from './MobileNav';
import { ChangeEvent } from 'react';
import { CategoryMenu } from './CategoryMenu';


function CategoryNav({ categories, isScrolled, isLandingPage }: { categories: ArticleCategory[], isScrolled: boolean, isLandingPage: boolean }) {

  return <CategoryMenu categories={categories} />


}

export type NavProps = { categories: ArticleCategory[] };

function Nav({ categories, onColorModeChange }: NavProps & { onColorModeChange: (e: ChangeEvent<HTMLInputElement>) => void }) {
  const isLandingPage = useIsLandigPage();

  const isScrolled = useIsScrolled();

  return (
    <Box
      className={clsx(
        'z-50 flex w-full flex-row items-center justify-between gap-12 overflow-auto px-8 py-2',
        {
          'bg-gray-100 dark:bg-default-950': isScrolled || !isLandingPage,
        }
      )}
    >
      <SkipNavLink>Direkt zum Inhalt</SkipNavLink>
      {(isScrolled || !isLandingPage) && (
        <div className="flex flex-row">
          <Link href="/">
            <StaticImage
              src="/LogoSVLB_Transparent.png"
              layout="fixed"
              height={50}
              width={50}
              alt="Das Vereinslogo"
            />
          </Link>

          <Link href="/">
            <div className=" whitespace-nowrap text-center before:-mr-0.5 before:inline-block before:h-full before:align-middle">
              <Heading className="text-default-900 dark:text-gray-200 inline-block align-middle">
                SVL - Bogensport
              </Heading>
            </div>
          </Link>
        </div>
      )}
      <div className="text-default-900 dark:text-gray-200 flex w-full flex-row justify-between self-end pr-4">

        <CategoryNav categories={categories} isLandingPage={isLandingPage} isScrolled={isScrolled} />

        <Menu strategy="fixed" boundary="scrollParent">
          <MenuButton
            as={Button}
            rightIcon={<BsChevronDown />}
            variant="link"
            textColor={isScrolled || !isLandingPage ? 'currentcolor' : 'white'}
          >
            Verein
          </MenuButton>
          <MenuList>
            <MenuItem as={Link} href="/verein">
              Über uns
            </MenuItem>
            <MenuItem as={Link} href="/verein/trainer">
              Unsere Trainer
            </MenuItem>
            <MenuItem as={Link} href="/verein/training">
              Training
            </MenuItem>
            <MenuItem as={Link} href="/verein/vorstand">
              Der Vorstand
            </MenuItem>
          </MenuList>
        </Menu>

        <Link passHref href="/gallery">
          <Button
            variant="link"
            textColor={isScrolled || !isLandingPage ? 'currentcolor' : 'white'}
          >
            Galerie
          </Button>
        </Link>

        <Link passHref href="/anfaengerInfos">
          <Button
            variant="link"
            textColor={isScrolled || !isLandingPage ? 'currentcolor' : 'white'}
          >
            Infos für Anfänger
          </Button>
        </Link>
      </div>
      <Show below="md">
        <MobileNav categories={categories} onColorModeChange={onColorModeChange} />
      </Show>
    </Box>
  );
}

export { Nav };
