import { Button, } from "@chakra-ui/react";
import { Menu, MenuItem, SubMenu, MenuButton } from "@szhsin/react-menu";
import { BsChevronDown } from "react-icons/bs";
import { ArticleCategory } from "../../types";
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { rawListeners } from "process";

type FilterArticles = (cat: ArticleCategory) => boolean

const defaultExperssion: FilterArticles = (category: ArticleCategory) => category.attributes.einordnung.data === null
export function CategoryMenu(props: { categories: ArticleCategory[] }) {


  const menuItemClass = ({ hover, disabled, submenu }: { hover: boolean, disabled: boolean, submenu: boolean }) => `dark:hover:bg-[#434c5b] ${hover ? 'bg-[#434c5b]' : ''} dark:text-white`
  const menuClass = "dark:bg-[#374151] dark:text-white mt-20"


  function renderChilds(categories: ArticleCategory[], expression?: FilterArticles) {

    const toplevelCategories = categories.filter(expression ? expression : defaultExperssion);
    return toplevelCategories.map(category => {

      const slug = category.attributes.rawSlug ? category.attributes.slug : `/article/category/${category.attributes.slug}`

      if (category.attributes.categories.data.length > 0) {
        return <SubMenu label={category.attributes.name} menuClassName={menuClass} itemProps={{ className: menuItemClass as any }}>
          <MenuItem className={menuItemClass as any} href={slug}>Alle</MenuItem>
          {renderChilds(categories, (cat) => cat.attributes.einordnung.data?.id === category.id)}
        </SubMenu>
      } else {
        return <MenuItem className={menuItemClass as any} href={slug}>{category.attributes.name}</MenuItem>
      }
    })
  }



  const categories: ArticleCategory[] = [props.categories[0], {
    id: -1,
    attributes: {
      categories: { data: [] },
      createdAt: '',
      createdBy: props.categories[0].attributes.createdBy,
      einordnung: { data: null },
      liste: false,
      name: 'Wettkampfergebnisse',
      publishedAt: '',
      seo: undefined,
      slug: 'wettkapmfergebnisse',
      updatedAt: '',
      updatedBy: props.categories[0].attributes.updatedBy,
      rawSlug: true
    }
  }, ...props.categories.slice(1)]

  return <Menu menuClassName={menuClass} overflow="visible" position="auto" direction="bottom" menuButton={<Button rightIcon={<BsChevronDown />}
    variant="link"
    textColor={'white'}>
    Berichte & Nachrichten
  </Button>}>
    {renderChilds(categories)}
  </Menu>
}