module.exports = {
  chatelle: {
    50: '#f9f8fa',
    100: '#f3f2f5',
    200: '#eae7ed',
    300: '#dad3df',
    400: '#bbb0c4',
    500: '#ab9db5',
    600: '#9985a2',
    700: '#85738e',
    800: '#706077',
    900: '#5c5062',
    950: '#3d3441',
  },
  rock: {
    50: '#f3f7fa',
    100: '#e9eff6',
    200: '#d8e1ed',
    300: '#c0cce1',
    400: '#a2b1d2',
    500: '#8e9cc6',
    600: '#7781b4',
    700: '#656d9d',
    800: '#535a80',
    900: '#474c68',
    950: '#2a2d3c',
  },
  fijord: {
    //fijord
    50: '#f6f6f9',
    100: '#ebebf3',
    200: '#d3d3e4',
    300: '#adaecc',
    400: '#8184af',
    500: '#616496',
    600: '#464772',
    700: '#3f4065',
    800: '#373855',
    900: '#313149',
    950: '#222130',
  },
  'tower-gray': {
    50: '#f6f9f9',
    100: '#edf0f1',
    200: '#d7dfe0',
    300: '#b1c3c4',
    400: '#8aa5a6',
    500: '#6c8a8b',
    600: '#567173',
    700: '#475b5d',
    800: '#3d4e4f',
    900: '#364344',
    950: '#242b2d',
  },
  'fire-bush': {
    50: '#fdf9ef',
    100: '#fbf0d9',
    200: '#f6deb2',
    300: '#f0c781',
    400: '#e9a74e',
    500: '#e59234',
    600: '#d57521',
    700: '#b15a1d',
    800: '#8d471f',
    900: '#723c1c',
    950: '#3d1d0d',
  },
};