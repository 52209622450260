import { AlertStatus } from '@chakra-ui/react';

export enum Weekday {
  Montag = 'Montag',
  Dienstag = 'Dienstag',
  Mittwoch = 'Mittwoch',
  Donnerstag = 'Donnerstag',
  Freitag = 'Freitag',
  Samstag = 'Samstag',
  Sonntag = 'Sonntag'
}

export interface ContentAutor {
  firstname: string;
  lastname: string;
  email: string;
}

export interface BasicStrapiEntity<T extends {}, SEO = undefined> {
  id: number;
  attributes: {
    seo: SEO;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    createdBy: {
      data: BasicStrapiEntity<StrapiAuthor>;
    };
    updatedBy: {
      data: BasicStrapiEntity<StrapiAuthor>;
    };
  } & T;
}

export interface PageContentEntry {
  id: string;
  name: string;
  path: string;
}

export interface LayoutConfig {
  layout: {
    showEvents: boolean
    containerWidth: 'container.xl' | 'container.lg' | 'container.md' | 'container.sm' | string
  }
}

export interface StrapiGlobal {
  id: number;
  attributes: StrapiGlobalAttributes;
  meta: {
    pageContent: PageContentEntry[];
    
  };
}

export interface PagePropsWithGlobal {
  meta: {
    pageContent?: PageContentEntry[];
  };
}

export interface StrapiGlobalAttributes {
  siteName: string;
  siteDescription: string;
  createdAt: string;
  updatedAt: string;
  favicon: { data: StrapiImage };
  defaultSeo: StrapiSeo;
}

export interface ImageSource {
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: Array<object>;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  previewUrl: string | null;
  provider: string;
  provider_metadata: any | null;
  createdAt: string;
  updatedAt: string;
  url: string;
}

export type StrapiImage = BasicStrapiEntity<ImageSource>;

export interface StrapiSeo {
  id?: number;
  metaTitle: string;
  metaDescription: string;
  shareImage?: { data: StrapiImage };
  article: boolean;
}

export type StrapiAuthor = BasicStrapiEntity<{
  name: string;
  email: string;
  avatar: { data: StrapiImage };
}>;

export type StrapiTrainer = BasicStrapiEntity<{
  slug: string;
  name: string;
  beschreibung: string;
  disziplin: string;
  licensesince: string;
  avatar: { data: StrapiImage };
}>;

export type Category = BasicStrapiEntity<{
  name: string;
  slug: string;
  description: string;
  liste: boolean;
  einordnung: {data: Category | null}
  categories: {data: Categories}
}>;

export type Categories = Category[];

export type ArticleCategory = BasicStrapiEntity<{
  slug: string;
  name: string;
  liste: boolean;
  einordnung: {data: Category | null}
  categories: {data: Categories},
  rawSlug?: boolean
}>;

export type Article = BasicStrapiEntity<{
  slug: number;
  cover: { data: StrapiImage };
  category?: { data: ArticleCategory };
  title: string;
  createdAt: string;
  publishedAt: string;
  content: string;
  teaser: string;
  blocks: ArticleTypes[];
  author?: { data: StrapiAuthor };
}>;

export type StrapiEvent = BasicStrapiEntity<{
  label: string;
  datum?: string;
  beschreibung: string;
  slug: string;
  detailansicht: boolean;
  content: Array<MediaArticleBlock | RichTextArticleBlock>;
}>;

export type StrapiAlert = {
  data: BasicStrapiEntity<{
    messages: {
      id: number;
      __component: string;
      label: string;
      content: string;
      ablaufdatum: string;
      anzeigen: boolean;
      type: AlertStatus;
    }[];
  }>;
};

export type ArticleBlockTypes =
  | 'shared.media'
  | 'shared.quote'
  | 'shared.content'
  | 'shared.slider'
  | 'shared.addresse'
  | 'shared.labeld-content' 
  | 'shared.weekday-with-time';

export interface IArtickleBlock<T extends ArticleBlockTypes> {
  id: number;
  __component: T;
}

export type ArticleBlock<I extends ArticleBlockTypes, Type> = {
  [Property in keyof Type]: Type[Property];
} & IArtickleBlock<I>;

export type RichTextArticleBlock = ArticleBlock<
  'shared.content',
  { body: string }
>;
export type QuoteArticleBlock = ArticleBlock<'shared.quote', { body: string }>;
export type MediaArticleBlock = ArticleBlock<
  'shared.media',
  { file: { data: StrapiImage } }
>;
export type SliderAricleBlock = ArticleBlock<
  'shared.slider',
  {
    files: { data: StrapiImage[] };
  }
>;

export type AddressBlock = ArticleBlock<'shared.addresse', {Strasse: string, Addressat: string, Plz: string, Stadt: string}>

export type ContentWithLabelBlock = ArticleBlock<'shared.labeld-content', { label: string, content: string }>

export type WeekdayWithTimeBlock = ArticleBlock<'shared.weekday-with-time', {day: Weekday, from: string, to: string}>

export type ArticleTypes =
  | RichTextArticleBlock
  | QuoteArticleBlock
  | MediaArticleBlock
  | SliderAricleBlock
  | AddressBlock
  | ContentWithLabelBlock
  | WeekdayWithTimeBlock;

export type StrapiHomepage = BasicStrapiEntity<{
  defaultSeo: StrapiSeo;
  siteName: string;
}>;

export interface GalleryFolder {
  id: number;
  __component: 'shared.folder';
  name: string;
  slider: boolean;
  files: {
    data: Array<StrapiImage>;
  };
}

export type StrapiGalleryData = BasicStrapiEntity<{
  title: string;
  slug: string;
  content: Array<GalleryFolder>;
  files: {
    data: StrapiImage[];
  };
  parent: {data: StrapiGalleryData } | null 
  galeries: {data: StrapiGalleryData[] }
}>;

export interface StrapiGallery {
  data: StrapiGalleryData[];
}

export type Impressum = BasicStrapiEntity<{
  Address: {
    id: number;
    Addressat: string;
    Strasse: string;
    Plz: string;
    Stadt: string;
  };
  ErsterVorstand: string;
  ZweiterVorstand: string;
  webmaster: string;
  hauptverein: string;
  haftungsausschluss: string;
}>;

export type Datenschutzerklärung = BasicStrapiEntity<{
  content: string;
}>;

export interface StrapiImpressum {
  data: Impressum[];
}
export type HallendienstEntry = BasicStrapiEntity<{
  verantwortlicher: string;
  date: {
    date: string;
    from: string;
    to: string;
  };
  kommentar: string;
}>;
export type HallendienstPlan = HallendienstEntry[];

export type TrainingsZeiten = BasicStrapiEntity<{
  blocks: ArticleTypes[]
  zeiten: {
    verantwortlicher?: string,
    anzeigen: boolean,
    date: {
      day: Weekday,
      from: string,
      to: string
    }
    title: string
  }[]
}>


export type WettkampfErgebnisDateien = StrapiImage[]

export type StrapiWetkampfergebnisse = BasicStrapiEntity<{
  zeilen: {
    id: number,
    Jahr: string,
    vm: { data: WettkampfErgebnisDateien | null },
    km: { data: WettkampfErgebnisDateien | null },
    bm: { data: WettkampfErgebnisDateien | null },
    lm: { data: WettkampfErgebnisDateien | null },
    dm: { data: WettkampfErgebnisDateien | null },
    sonstige: WettkampfErgebnisDateien | null
  }[]
}>

export type StrapiVorstand = BasicStrapiEntity<{
  blocks: ArticleTypes[]
}>